import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { hostnameGHN } from '~/utils/http';

export const getCities = createAsyncThunk('getCities', async () => {
  try {
    const res = await axios.post(`${hostnameGHN}master-data/province`, null, {
      headers: {
        token: '00ba6bec-7b17-11ef-a572-2a014b19441b',
      },
    });

    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Đã xảy ra lỗi không mong đợi');
  }
});

export const getDistricts = createAsyncThunk('getDistricts', async ({ provinceID }) => {
  try {
    const res = await axios.post(
      `${hostnameGHN}master-data/district`,
      { province_id: provinceID },
      {
        headers: {
          token: '00ba6bec-7b17-11ef-a572-2a014b19441b',
        },
      },
    );

    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Đã xảy ra lỗi không mong đợi');
  }
});

export const getWards = createAsyncThunk('getWards', async ({ districtID }) => {
  try {
    const res = await axios.post(
      `${hostnameGHN}master-data/ward`,
      { district_id: districtID },
      {
        headers: {
          token: '00ba6bec-7b17-11ef-a572-2a014b19441b',
        },
      },
    );

    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Đã xảy ra lỗi không mong đợi');
  }
});

export const getAvailableServices = createAsyncThunk('getAvailableServices', async ({ fromDistrict, toDistrict }) => {
  try {
    const res = await axios.post(
      `${hostnameGHN}v2/shipping-order/available-services`,
      {
        shop_id: 5346523,
        from_district: fromDistrict,
        to_district: toDistrict,
      },
      {
        headers: {
          token: '00ba6bec-7b17-11ef-a572-2a014b19441b',
        },
      },
    );

    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'Đã xảy ra lỗi không mong đợi');
  }
});

export const gHNExpressFee = createAsyncThunk(
  'gHNExpressFee',
  async ({ serviceID, price, from_district_id, to_district_id, to_ward_code, height, length, weight, width }) => {
    try {
      const res = await axios.post(
        `${hostnameGHN}v2/shipping-order/fee`,
        {
          service_id: serviceID,
          insurance_value: price,
          coupon: null,
          from_district_id: from_district_id,
          to_district_id: to_district_id,
          to_ward_code: to_ward_code,
          height: height,
          length: length,
          weight: weight,
          width: width,
        },
        {
          headers: {
            token: '00ba6bec-7b17-11ef-a572-2a014b19441b',
            shop_id: '5346523',
          },
        },
      );

      return res.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || 'Đã xảy ra lỗi không mong đợi');
    }
  },
);
