import { useState } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Oval } from '@agney/react-loading';

import styles from './ListCategories.module.scss';

import images from '~/assets/images';
import routes from '~/config/routes';
import NoResult from '~/components/NoResult';
import { getCategories } from '~/services/categoryService';

const cx = classNames.bind(styles);

function normalizeString(str) {
  return (
    str
      // .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/\s+/g, '-')
  );
}

function ListCategories() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, listCategories, data } = useSelector((state) => state.category);

  return (
    <div className={cx('list-categories', 'row', 'gx-xl-2', 'g-0')}>
      <InfiniteScroll
        scrollThreshold="20%"
        className={cx('infinite-scroll row')}
        dataLength={listCategories.length}
        next={() => {
          dispatch(getCategories({ limit: 8, page: currentPage }));
          setCurrentPage((pre) => pre + 1);
        }}
        hasMore={
          currentPage === 1 && !data.totalResult ? true : listCategories.length < data.totalResult ? true : false
        }
      >
        {listCategories.map((item, index) => (
          <Link
            to={`${routes.category.split('/')[0]}/${normalizeString(item.slug)}`}
            key={index}
            className={cx('col-xl-3 col-6')}
            onClick={() => {
              sessionStorage.setItem('categorySelected', JSON.stringify({ name: item.name, slug: item.slug }));
              sessionStorage.setItem('idCategorySelected', JSON.stringify(item?._id));
            }}
          >
            <div>
              <div className={cx('list-categories__item')} key={item._id}>
                <img className={cx('category-img')} src={item.image || images.defaultImg} alt={item.name} />
                <span className={cx('category-name')}>{item.name}</span>
              </div>
            </div>
          </Link>
        ))}
        {listCategories.length <= 0 && !loading && <NoResult />}
      </InfiniteScroll>

      {loading && (
        <div className={cx('list-categories__loader')}>
          <Oval width="50" color="var(--primary-bg)" />
        </div>
      )}
    </div>
  );
}

export default ListCategories;
