import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Oval } from '@agney/react-loading';

import styles from './Home.module.scss';

import Banner from '~/components/Banner';
import ListSlider from '~/components/ListSlider';
import Button from '~/components/Button/Button';
import ListCategories from '~/components/ListCategories';
import { CircleCloseIcon } from '~/components/Icons';
import images from '~/assets/images';
import ListResult from '~/components/ListResult';
import routes from '~/config/routes';

const cx = classNames.bind(styles);

function Home() {
  const { t } = useTranslation();

  const [isSearch, setIsSearch] = useState('false');
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(1);
  const [isRemove, setIsRemove] = useState(false);
  const [isFirstMount, setFirstMount] = useState(true);
  const loadingRef = useRef();

  const reduxData = useSelector((prop) => prop.product);

  const handleRemove = () => {
    setIsRemove(false);
  };

  const handleToggleSearch = (type) => {
    setIsSearch(type);
  };

  const handleSearchResult = (searchResult) => {
    setSearchResult(searchResult);
  };

  const handleChangePage = (value) => {
    setPage(value);
  };

  useEffect(() => {
    setFirstMount(false);
  }, []);

  // Scroll lên đầu trang khi chọn trang khác
  useEffect(() => {
    if (isFirstMount) {
      return;
    }

    const isMobile = window.matchMedia('(max-width: 960px)');

    if (loadingRef.current) {
      loadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (isMobile.matches) {
        loadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={cx('home')}>
      {/* Banner */}
      <div>
        <Banner
          onSearch={handleToggleSearch}
          onSearchResult={handleSearchResult}
          onPage={page}
          onHandleRemove={handleRemove}
          onRemove={isRemove}
        />
      </div>

      {/* Content */}
      <div className={cx('container gx-5')}>
        {reduxData.loading && (
          <div className={cx('home__search-loading-container')}>
            <Oval width="50" color="var(--primary-bg)" />
          </div>
        )}

        {/* Search result */}
        <div
          ref={loadingRef}
          className={cx(
            { 'home__search-result-loading': isSearch === 'loading' },
            'home__search-result-container',
            { 'home__search-result-show': isSearch === 'true' },
            { 'home__search-result-hidden': isSearch === 'false' },
          )}
        >
          <div className={cx('home__search-title-container')}>
            <div className={cx('home__search-title')}>{t('home.result-title')}</div>
            <div
              onClick={() => {
                setIsRemove(true);
                handleToggleSearch('false');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <div className={cx('home__close-container')}>
                <div className={cx('home__remove-title')}>{t('home.close-btn')}</div>
                <CircleCloseIcon className={cx('home__close-icon')} />
              </div>
            </div>
          </div>

          <ListResult data={searchResult} onChangePage={handleChangePage} />
        </div>

        <h1 className={cx('home__title-1')}>
          {t('home.title01')} <span className={cx('home__title-1--highlight')}>Mocca Cafe</span>
        </h1>

        {/* New store listing */}
        <ListSlider />
        <Button primary className={cx('home__btn')} to={'restaurants'}>
          {t('home.see-all-restaurants')}
        </Button>

        {/* List of categories */}
        <h2 className={cx('home__title-2', 'home__title--margin')}>{t('home.title02')}</h2>
        <ListCategories />
      </div>

      {/* About Mocca Cafe */}
      <section className={cx('home__contact')}>
        <div className={cx('container gx-5')}>
          <div className={cx('home__contact-container')}>
            <div className={cx('home__contact-copyright')}></div>
            <h2 className={cx('home__contact-title', 'home__contact-title--primary')}>{t('home.title05')}</h2>
            <h2 className={cx('home__contact-title')}>{t('home.title06')}</h2>
            <p className={cx('home__contact-desc')}>{t('home.desc03')}</p>
            <Link to={routes.aboutMoccaCafe}>
              <Button more primary>
                {t('home.btn01')}
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Info Mocca Cafe */}
      <article className={cx('home__info')}>
        <div className={cx('container gx-5')}>
          <div className={cx('row', 'justify-content-center')}>
            <div className={cx('col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6')}>
              <section className={cx('home__info-item', 'home__info-item--nbl')}>
                <img lazy="loading" src={images.homeInfo1} alt={t('home.title07')} className={cx('home__info-img')} />
                <h4 className={cx('home__info-title')}>{t('home.title07')}</h4>
                <p className={cx('home__info-desc')}>{t('home.desc04')}</p>
              </section>
            </div>
            <div className={cx('col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6')}>
              <section className={cx('home__info-item', 'home__info-item--nbl')}>
                <img lazy="loading" src={images.homeInfo2} alt={t('home.title08')} className={cx('home__info-img')} />
                <h4 className={cx('home__info-title')}>{t('home.title08')}</h4>
                <p className={cx('home__info-desc')}>{t('home.desc05')}</p>
              </section>
            </div>
            <div className={cx('col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6 gy-lg-0 gy-md-5')}>
              <section className={cx('home__info-item', 'home__info-item--nb')}>
                <img lazy="loading" src={images.homeInfo3} alt={t('home.title09')} className={cx('home__info-img')} />
                <h4 className={cx('home__info-title')}>{t('home.title09')}</h4>
                <p className={cx('home__info-desc')}>{t('home.desc06')}</p>
              </section>
            </div>
          </div>
        </div>
      </article>

      {/* Banner footer */}
      <div className={cx('banner-footer')}>
        <div className={cx('container gx-5')}>
          <div className={cx('row')}>
            <div className={cx('col-md-6 col-12')}>
              <div className={cx('banner-footer__left')}>
                <img
                  lazy="loading"
                  src={images.curatedCafes}
                  alt="Mocca Cafe"
                  className={cx('banner-footer__left-img')}
                />

                <h4 className={cx('banner-footer__title')}>{t('home.title03')}</h4>
                <p className={cx('banner-footer__desc')}>{t('home.desc01')}</p>
              </div>
            </div>
            <div className={cx('col-md-6 col-12')}>
              <div className={cx('banner-footer__right')}>
                <img lazy="loading" src={images.moreApp} alt="Mocca Cafe" className={cx('banner-footer__right-img')} />
                <h4 className={cx('banner-footer__title')}>{t('home.title04')}</h4>
                <p className={cx('banner-footer__desc')}>{t('home.desc02')}</p>

                <div className={cx('banner-footer__logo-container')}>
                  <img lazy="loading" src={images.appStore} className={cx('banner-footer__logo')} alt="Mocca Cafe" />
                  <img lazy="loading" src={images.googlePlay} className={cx('banner-footer__logo')} alt="Mocca Cafe" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
