export const CopyIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8V7C10 6.05719 10 5.58579 10.2929 5.29289C10.5858 5 11.0572 5 12 5H17C17.9428 5 18.4142 5 18.7071 5.29289C19 5.58579 19 6.05719 19 7V12C19 12.9428 19 13.4142 18.7071 13.7071C18.4142 14 17.9428 14 17 14H16M7 19H12C12.9428 19 13.4142 19 13.7071 18.7071C14 18.4142 14 17.9428 14 17V12C14 11.0572 14 10.5858 13.7071 10.2929C13.4142 10 12.9428 10 12 10H7C6.05719 10 5.58579 10 5.29289 10.2929C5 10.5858 5 11.0572 5 12V17C5 17.9428 5 18.4142 5.29289 18.7071C5.58579 19 6.05719 19 7 19Z"
        stroke="#464455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CoinIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M512 0.745631C230.270757 0.745631 0.745631 230.270757 0.745631 512c0 281.729243 229.525126 511.254369 511.254369 511.254369 281.729243 0 511.254369-229.525126 511.254369-511.254369C1023.254369 230.270757 793.729243 0.745631 512 0.745631z m0 962.311456c-248.752466 0-451.057087-202.304621-451.057087-451.057087 0-248.752466 202.304621-451.057087 451.057087-451.057087 248.752466 0 451.057087 202.304621 451.057087 451.057087 0 248.752466-202.304621 451.057087-451.057087 451.057087z"
        fill="currentColor"
      />
      <path
        d="M634.134369 518.243417c-25.480699-19.22734-59.700194-30.222913-89.913165-38.464621l-2.753864-0.745631V354.403417l4.503611 0.994175c26.723417 5.994874 51.697087 17.736078 72.674175 34.219495 2.246835 1.749748 5.249243 2.753864 8.251651 2.246835a10.935922 10.935922 0 0 0 7.486136-4.245126l22.229747-28.721709a11.075107 11.075107 0 0 0-1.749747-15.479301c-30.222913-24.476583-67.18633-39.717282-109.896078-45.463611l-3.24101-0.497088v-45.950757c0-6.243417-5.000699-11.234175-11.244116-11.234175h-40.214369c-6.243417 0-11.234175 4.990757-11.234175 11.234175v46.954874l-3.250951 0.497087c-35.462214 5.497786-65.436583 19.22734-86.41367 40.214369-20.728544 20.728544-31.962718 47.203417-31.962719 76.919301 0 60.197282 33.205437 92.657087 118.624932 116.139495l2.753864 0.745631v138.617787l-4.49367-0.994175c-30.72-6.99899-60.942913-22.229748-84.673864-42.709748a12.258175 12.258175 0 0 0-8.738796-2.753864 10.40901 10.40901 0 0 0-7.744621 4.49367l-21.722719 30.222913c-3.250951 4.49367-2.753864 10.995573 1.491263 14.73367 30.72 28.73165 72.177087 46.457786 122.88 52.701204l3.250951 0.497087v44.956583c0 6.243417 4.990757 11.234175 11.234175 11.234174h40.214369c6.243417 0 11.234175-4.990757 11.234174-11.234174v-43.445437l3.250952-0.258486c40.462913-4.245126 72.425631-17.487534 95.162408-39.210252 22.726835-21.73266 34.965126-51.955573 34.965126-86.920699-0.248544-36.207845-13.490951-63.686835-40.96-84.663923z m-92.915573 30.72l5.000699 1.491263c29.725825 9.494369 63.686835 24.983612 63.686835 59.45165a57.244583 57.244583 0 0 1-14.485126 37.957592c-11.234175 12.74532-27.976078 21.235573-49.708738 25.232156l-4.49367 0.745631V548.963417zM422.832466 410.594175c0-27.717592 18.740194-48.446136 51.2-56.439301l4.752155-1.25266v108.643417l-5.000699-1.739806c-21.722718-6.99899-50.951456-20.231456-50.951456-49.21165z"
        fill="currentColor"
      />
    </svg>
  );
};

export const StoreIcon = ({ className, width = '2.2rem', height = '2.2rem' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M22,7H18.414L12.707,1.293a1,1,0,0,0-1.414,0L5.586,7H2A1,1,0,0,0,1,8V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM12,3.414,15.586,7H8.414ZM21,21H3V9H21ZM5,13a1,1,0,0,1,1-1H18a1,1,0,0,1,0,2H6A1,1,0,0,1,5,13Zm12,4a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h8A1,1,0,0,1,17,17Z"></path>
      </g>
    </svg>
  );
};

export const HistoryOderIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32.402 32" id="cart">
      <path d="M6 30a2 2 1080 1 0 4 0 2 2 1080 1 0-4 0zm18 0a2 2 1080 1 0 4 0 2 2 1080 1 0-4 0zM-.058 5a1 1 0 0 0 1 1H3.02l1.242 5.312L6 20c0 .072.034.134.042.204l-1.018 4.58A.997.997 0 0 0 6 26h22.688a1 1 0 0 0 0-2H7.248l.458-2.06c.1.016.19.06.294.06h18.23c1.104 0 1.77-.218 2.302-1.5l3.248-9.964C32.344 8.75 31.106 8 30 8H6c-.156 0-.292.054-.438.088l-.776-3.316A1 1 0 0 0 3.812 4H.942a1 1 0 0 0-1 1zm6.098 5h23.81l-3.192 9.798c-.038.086-.07.148-.094.19-.066.006-.17.012-.334.012H8v-.198l-.038-.194L6.04 10z" />
    </svg>
  );
};

export const PowerOffIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M288 256C288 273.7 273.7 288 256 288C238.3 288 224 273.7 224 256V32C224 14.33 238.3 0 256 0C273.7 0 288 14.33 288 32V256zM80 256C80 353.2 158.8 432 256 432C353.2 432 432 353.2 432 256C432 201.6 407.3 152.9 368.5 120.6C354.9 109.3 353 89.13 364.3 75.54C375.6 61.95 395.8 60.1 409.4 71.4C462.2 115.4 496 181.8 496 255.1C496 388.5 388.5 496 256 496C123.5 496 16 388.5 16 255.1C16 181.8 49.75 115.4 102.6 71.4C116.2 60.1 136.4 61.95 147.7 75.54C158.1 89.13 157.1 109.3 143.5 120.6C104.7 152.9 80 201.6 80 256z" />
    </svg>
  );
};

export const RefreshIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
    </svg>
  );
};

export const KeyIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 485.017 485.017"
    >
      <g>
        <path d="M361.205,68.899c-14.663,0-28.447,5.71-38.816,16.078c-21.402,21.403-21.402,56.228,0,77.631 c10.368,10.368,24.153,16.078,38.815,16.078s28.447-5.71,38.816-16.078c21.402-21.403,21.402-56.228,0-77.631 C389.652,74.609,375.867,68.899,361.205,68.899z M378.807,141.394c-4.702,4.702-10.953,7.292-17.603,7.292 s-12.901-2.59-17.603-7.291c-9.706-9.706-9.706-25.499,0-35.205c4.702-4.702,10.953-7.291,17.603-7.291 s12.9,2.589,17.603,7.291 C388.513,115.896,388.513,131.688,378.807,141.394z" />
        <path d="M441.961,43.036C414.21,15.284,377.311,0,338.064,0c-39.248,0-76.146,15.284-103.897,43.036 c-42.226,42.226-54.491,105.179-32.065,159.698L0.254,404.584l-0.165,80.268l144.562,0.165v-55.722h55.705l0-55.705h55.705v-64.492 l26.212-26.212c17.615,7.203,36.698,10.976,55.799,10.976c39.244,0,76.14-15.282,103.889-43.032 C499.25,193.541,499.25,100.325,441.961,43.036z M420.748,229.617c-22.083,22.083-51.445,34.245-82.676,34.245 c-18.133,0-36.237-4.265-52.353-12.333l-9.672-4.842l-49.986,49.985v46.918h-55.705l0,55.705h-55.705v55.688l-84.5-0.096 l0.078-37.85L238.311,208.95l-4.842-9.672c-22.572-45.087-13.767-99.351,21.911-135.029 C277.466,42.163,306.83,30,338.064,30c31.234,0,60.598,12.163,82.684,34.249 C466.34,109.841,466.34,184.025,420.748,229.617z" />
      </g>
    </svg>
  );
};

export const HideIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="eye">
      <g fill="none" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24M1 1l22 22" />
      </g>
    </svg>
  );
};

export const EyeIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="eye">
      <path d="M25 39c13.036 0 23.352-12.833 23.784-13.379l.491-.621-.491-.621C48.352 23.833 38.036 11 25 11S1.648 23.833 1.216 24.379L.725 25l.491.621C1.648 26.167 11.964 39 25 39zm0-26c10.494 0 19.47 9.46 21.69 12C44.473 27.542 35.509 37 25 37 14.506 37 5.53 27.54 3.31 25 5.527 22.458 14.491 13 25 13z" />
      <path d="M25 34c4.963 0 9-4.038 9-9s-4.037-9-9-9-9 4.038-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.14 7 7s-3.141 7-7 7-7-3.14-7-7 3.141-7 7-7z" />
    </svg>
  );
};

export const TermsOfUseIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.750092C15.108 0.750092 19.25 4.89109 19.25 10.0001C19.25 15.1081 15.108 19.2501 10 19.2501C4.891 19.2501 0.75 15.1081 0.75 10.0001C0.75 4.89109 4.891 0.750092 10 0.750092Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99512 6.20419V10.6232"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.995 13.7961H10.005"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const HelpIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.334 0.750092H5.665C2.644 0.750092 0.75 2.88909 0.75 5.91609V14.0841C0.75 17.1111 2.635 19.2501 5.665 19.2501H14.333C17.364 19.2501 19.25 17.1111 19.25 14.0841V5.91609C19.25 2.88909 17.364 0.750092 14.334 0.750092Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99414 14.0001V10.0001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99012 6.20419H10.0001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PersonalInfoIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98468 13.3457C4.11707 13.3457 0.814209 13.9305 0.814209 16.2724C0.814209 18.6143 4.09611 19.22 7.98468 19.22C11.8523 19.22 15.1542 18.6343 15.1542 16.2933C15.1542 13.9524 11.8733 13.3457 7.98468 13.3457Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98464 10.0059C10.5227 10.0059 12.5799 7.94779 12.5799 5.40969C12.5799 2.8716 10.5227 0.814453 7.98464 0.814453C5.44655 0.814453 3.38845 2.8716 3.38845 5.40969C3.37988 7.93922 5.42369 9.99731 7.95226 10.0059H7.98464Z"
        stroke="currentColor"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronRight = ({ className }) => {
  return (
    <svg
      className={className}
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 1L8.5 8L1.5 15" stroke="#9E9DA8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const TrashIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
    </svg>
  );
};

export const CircleCloseIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
    </svg>
  );
};

export const LoadingIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
    </svg>
  );
};

export const CheckIcon = ({ className, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
    </svg>
  );
};

export const HaftStarIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M288 376.4l.1-.1 26.4 14.1 85.2 45.5-16.5-97.6-4.8-28.7 20.7-20.5 70.1-69.3-96.1-14.2-29.3-4.3-12.9-26.6L288.1 86.9l-.1 .3V376.4zm175.1 98.3c2 12-3 24.2-12.9 31.3s-23 8-33.8 2.3L288.1 439.8 159.8 508.3C149 514 135.9 513.1 126 506s-14.9-19.3-12.9-31.3L137.8 329 33.6 225.9c-8.6-8.5-11.7-21.2-7.9-32.7s13.7-19.9 25.7-21.7L195 150.3 259.4 18c5.4-11 16.5-18 28.8-18s23.4 7 28.8 18l64.3 132.3 143.6 21.2c12 1.8 22 10.2 25.7 21.7s.7 24.2-7.9 32.7L438.5 329l24.6 145.7z" />
    </svg>
  );
};

export const EmptyStarIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
    </svg>
  );
};

export const StarIcon = ({ className }) => {
  return (
    <svg fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
    </svg>
  );
};

export const SearchIcon = ({ width = '2.2rem', height = '2.2rem', className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill="none"
    >
      <circle
        cx="9.7659"
        cy="9.7659"
        r="8.98856"
        stroke="#1A162E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0176 16.4844L19.5416 19.9992"
        stroke="#1A162E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowDownIcon = ({ width = '1.2rem', height = '1.2rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path
      d="M8.5 1.25L5 4.75L1.5 1.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowRightIcon = ({ width = '2.2rem', height = '2.2rem', color = '#676767', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width={width}
      height={height}
      color={color}
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );
};

export const CloseIcon = ({ width = '2.4rem', height = '2.4rem', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g fill="#676767" fillRule="nonzero">
        <path d="M19 4.293l.707.707L5 19.707 4.293 19z" />
        <path d="M19.707 19l-.707.707L4.293 5 5 4.293z" />
      </g>
    </g>
  </svg>
);

export const CartIcon = ({ width = '2rem', height = '2.2rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M0.75 1.25L2.83 1.61L3.793 13.083C3.87 14.02 4.653 14.739 5.593 14.736H16.502C17.399 14.738 18.16 14.078 18.287 13.19L19.236 6.632C19.342 5.899 18.833 5.219 18.101 5.113C18.037 5.104 3.164 5.099 3.164 5.099"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.125 8.79492H14.898" stroke="#1A162E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.15338 18.2031C5.45438 18.2031 5.69738 18.4471 5.69738 18.7471C5.69738 19.0481 5.45438 19.2921 5.15338 19.2921C4.85238 19.2921 4.60938 19.0481 4.60938 18.7471C4.60938 18.4471 4.85238 18.2031 5.15338 18.2031Z"
      fill="#1A162E"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4346 18.2031C16.7356 18.2031 16.9796 18.4471 16.9796 18.7471C16.9796 19.0481 16.7356 19.2921 16.4346 19.2921C16.1336 19.2921 15.8906 19.0481 15.8906 18.7471C15.8906 18.4471 16.1336 18.2031 16.4346 18.2031Z"
      fill="#1A162E"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BagIcon = ({ width = '2.1rem', height = '2.2rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5134 20.5003H6.16555C3.09919 20.5003 0.746786 19.3928 1.41498 14.9351L2.19301 8.89394C2.60491 6.66968 4.02367 5.81842 5.26852 5.81842H15.447C16.7102 5.81842 18.0466 6.73375 18.5225 8.89394L19.3006 14.9351C19.8681 18.8894 17.5797 20.5003 14.5134 20.5003Z"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6502 5.59873C14.6502 3.21265 12.716 1.27836 10.3299 1.27836V1.27836C9.18088 1.27349 8.07727 1.72652 7.26308 2.53727C6.44889 3.34803 5.9912 4.44971 5.99121 5.59873H5.99121"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.296 10.1021H13.2502" stroke="#1A162E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.46492 10.1022H7.41916"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClockIcon = ({ width = '2rem', height = '2rem', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="9" stroke="#676767" strokeLinecap="square" />
      <path fill="#676767" fillRule="nonzero" d="M17.41 11.5v1H11.5V6.59h1v4.91z" />
    </g>
  </svg>
);

export const PlusIcon = ({ width = '1.6rem', height = '1.6rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/16px/add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M9,9 L9,13.8925781 L7,13.8925781 C7,13.8925781 7,13.4882853 7,12.6796875 C7,11.8710897 7,10.8710997 7,9.6796875 L7,9 L6.21289062,9 C5.02147842,9 4.02148842,9 3.21289062,9 C2.40429283,9 2,9 2,9 L2,7 L7,7 L7,6.10546875 C7,4.91405654 7,3.91406654 7,3.10546875 C7,2.29687096 7,1.89257812 7,1.89257812 L9,1.89257813 L9,7 L14,7 L14,9 C14,9 13.5957072,9 12.7871094,9 C11.9785116,9 10.9785216,9 9.78710938,9 L9,9 Z"
        id="Combined-Shape"
        fill="#4d8b55"
      ></path>
    </g>
  </svg>
);

export const MinusIcon = ({ width = '1.6rem', height = '1.6rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/16px/remove" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M14,9 C14,9 13.5957072,9 12.7871094,9 C11.9785116,9 10.9785216,9 9.78710937,9 C8.59569717,9 7.40430283,9 6.21289062,9 C5.02147842,9 4.02148842,9 3.21289062,9 C2.40429283,9 2,9 2,9 L2,7 L14,7 L14,9 Z"
        id="–"
        fill="#4d8b55"
      ></path>
    </g>
  </svg>
);

export const MenuIcon = ({ width = '1.2rem', height = '1.6rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 17"
    fill="none"
  >
    <path d="M13 1.5H1" stroke="#1A162E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 9H1" stroke="#1A162E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 16H1" stroke="#1A162E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const HomeIcon = ({ width = '2.2rem', height = '2.2rem', className }) => (
  <svg
    fill="#fff"
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 486.196 486.196"
  >
    <g>
      <path
        d="M481.708,220.456l-228.8-204.6c-0.4-0.4-0.8-0.7-1.3-1c-5-4.8-13-5-18.3-0.3l-228.8,204.6c-5.6,5-6,13.5-1.1,19.1
		c2.7,3,6.4,4.5,10.1,4.5c3.2,0,6.4-1.1,9-3.4l41.2-36.9v7.2v106.8v124.6c0,18.7,15.2,34,34,34c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0h70.6
		c17.6,0,31.9-14.3,31.9-31.9v-121.3c0-2.7,2.2-4.9,4.9-4.9h72.9c2.7,0,4.9,2.2,4.9,4.9v121.3c0,17.6,14.3,31.9,31.9,31.9h72.2
		c19,0,34-18.7,34-42.6v-111.2v-34v-83.5l41.2,36.9c2.6,2.3,5.8,3.4,9,3.4c3.7,0,7.4-1.5,10.1-4.5
		C487.708,233.956,487.208,225.456,481.708,220.456z M395.508,287.156v34v111.1c0,9.7-4.8,15.6-7,15.6h-72.2c-2.7,0-4.9-2.2-4.9-4.9
		v-121.1c0-17.6-14.3-31.9-31.9-31.9h-72.9c-17.6,0-31.9,14.3-31.9,31.9v121.3c0,2.7-2.2,4.9-4.9,4.9h-70.6c-0.3,0-0.5,0-0.8,0
		s-0.5,0-0.8,0c-3.8,0-7-3.1-7-7v-124.7v-106.8v-31.3l151.8-135.6l153.1,136.9L395.508,287.156L395.508,287.156z"
      />
    </g>
  </svg>
);

export const FacebookIcon = ({ width = '2.8rem', height = '2.8rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.067C0 18.033 4.333 22.994 10 24V15.333H7V12H10V9.333C10 6.333 11.933 4.667 14.667 4.667C15.533 4.667 16.467 4.8 17.333 4.933V8H15.8C14.333 8 14 8.733 14 9.667V12H17.2L16.667 15.333H14V24C19.667 22.994 24 18.034 24 12.067C24 5.43 18.6 0 12 0C5.4 0 0 5.43 0 12.067Z"
      fill="white"
    />
  </svg>
);

export const InstagramIcon = ({ width = '2.6rem', height = '2.6rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        d="M11.9968 7.9983C9.79333 7.9983 7.99515 9.79651 7.99515 12C7.99515 14.2035 9.79333 16.0017 11.9968 16.0017C14.2002 16.0017 15.9984 14.2035 15.9984 12C15.9984 9.79651 14.2002 7.9983 11.9968 7.9983ZM23.9987 12C23.9987 10.3429 24.0137 8.70077 23.9206 7.04665C23.8275 5.12536 23.3893 3.4202 21.9843 2.01525C20.5764 0.607302 18.8743 0.172008 16.953 0.0789456C15.2959 -0.0141173 13.6539 0.000892936 11.9998 0.000892936C10.3427 0.000892936 8.70061 -0.0141173 7.04652 0.0789456C5.12526 0.172008 3.42014 0.610305 2.01522 2.01525C0.607291 3.42321 0.172005 5.12536 0.0789442 7.04665C-0.014117 8.70377 0.000892919 10.3459 0.000892919 12C0.000892919 13.6541 -0.014117 15.2992 0.0789442 16.9533C0.172005 18.8746 0.610293 20.5798 2.01522 21.9847C3.42314 23.3927 5.12526 23.828 7.04652 23.9211C8.70361 24.0141 10.3457 23.9991 11.9998 23.9991C13.6569 23.9991 15.2989 24.0141 16.953 23.9211C18.8743 23.828 20.5794 23.3897 21.9843 21.9847C23.3923 20.5768 23.8275 18.8746 23.9206 16.9533C24.0167 15.2992 23.9987 13.6571 23.9987 12ZM11.9968 18.1572C8.58954 18.1572 5.83973 15.4073 5.83973 12C5.83973 8.5927 8.58954 5.84284 11.9968 5.84284C15.404 5.84284 18.1538 8.5927 18.1538 12C18.1538 15.4073 15.404 18.1572 11.9968 18.1572ZM18.406 7.02864C17.6105 7.02864 16.968 6.38621 16.968 5.59067C16.968 4.79513 17.6105 4.1527 18.406 4.1527C19.2015 4.1527 19.8439 4.79513 19.8439 5.59067C19.8442 5.77957 19.8071 5.96667 19.735 6.14124C19.6628 6.31581 19.5569 6.47442 19.4233 6.608C19.2897 6.74157 19.1311 6.84748 18.9565 6.91967C18.782 6.99185 18.5949 7.02888 18.406 7.02864Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GithubIcon = ({ width = '2.8rem', height = '2.8rem', className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 16 16" version="1.1">
    <path
      fill="#fff"
      d="M8 0c4.42 0 8 3.58 8 8a8.013 8.013 0 0 1-5.45 7.59c-.4.08-.55-.17-.55-.38 0-.27.01-1.13.01-2.2 0-.75-.25-1.23-.54-1.48 1.78-.2 3.65-.88 3.65-3.95 0-.88-.31-1.59-.82-2.15.08-.2.36-1.02-.08-2.12 0 0-.67-.22-2.2.82-.64-.18-1.32-.27-2-.27-.68 0-1.36.09-2 .27-1.53-1.03-2.2-.82-2.2-.82-.44 1.1-.16 1.92-.08 2.12-.51.56-.82 1.28-.82 2.15 0 3.06 1.86 3.75 3.64 3.95-.23.2-.44.55-.51 1.07-.46.21-1.61.55-2.33-.66-.15-.24-.6-.83-1.23-.82-.67.01-.27.38.01.53.34.19.73.9.82 1.13.16.45.68 1.31 2.69.94 0 .67.01 1.3.01 1.49 0 .21-.15.45-.55.38A7.995 7.995 0 0 1 0 8c0-4.42 3.58-8 8-8Z"
    ></path>
  </svg>
);

export const GoogleIcon = ({ width = '2.4rem', height = '2.4rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
  >
    <defs>
      <path
        id="a"
        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
      />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
    <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
    <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
    <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
  </svg>
);

export const EmailIcon = ({ width = '2.2rem', height = '2rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
  >
    <path
      d="M16.9024 6.85156L12.4591 10.4646C11.6196 11.1306 10.4384 11.1306 9.59895 10.4646L5.11816 6.85156"
      stroke="#9E9DA8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z"
      stroke="#9E9DA8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PasswordIcon = ({ width = '1.8rem', height = '2rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      d="M13.4228 7.44804V5.30104C13.4228 2.78804 11.3848 0.750045 8.87176 0.750045C6.35876 0.739045 4.31276 2.76704 4.30176 5.28104V5.30104V7.44804"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.683 19.2498H5.042C2.948 19.2498 1.25 17.5528 1.25 15.4578V11.1688C1.25 9.07383 2.948 7.37683 5.042 7.37683H12.683C14.777 7.37683 16.475 9.07383 16.475 11.1688V15.4578C16.475 17.5528 14.777 19.2498 12.683 19.2498Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.8623 12.2031V14.4241"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIcon = ({ width = '1.6rem', height = '2rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98468 13.3457C4.11707 13.3457 0.814209 13.9305 0.814209 16.2724C0.814209 18.6143 4.09611 19.22 7.98468 19.22C11.8523 19.22 15.1542 18.6343 15.1542 16.2933C15.1542 13.9524 11.8733 13.3457 7.98468 13.3457Z"
      stroke="#9E9DA8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98464 10.0059C10.5227 10.0059 12.5799 7.94779 12.5799 5.40969C12.5799 2.8716 10.5227 0.814453 7.98464 0.814453C5.44655 0.814453 3.38845 2.8716 3.38845 5.40969C3.37988 7.93922 5.42369 9.99731 7.95226 10.0059H7.98464Z"
      stroke="#9E9DA8"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const LogOutIcon = ({ width = '1.6rem', height = '1.6rem', className }) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471.2 471.2">
    <g>
      <g>
        <path
          d="M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5
			s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5
			S235.019,444.2,227.619,444.2z"
        />
        <path
          d="M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5
			s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8
			C455.319,239.9,455.319,231.3,450.019,226.1z"
        />
      </g>
    </g>
  </svg>
);

export const tagIcon = ({ width = '1.6rem', height = '1.6rem', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
      width={width}
      height={height}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6Z" />
    </svg>
  );
};

export const CalendarIcon = ({ width = '2rem', height = '2rem', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      width={width}
      height={height}
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
      />
    </svg>
  );
};

export const ArrowLeftIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>
  );
};

export const plusIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
  );
};

export const QuotesIcon = ({ width = '3rem', height = '3rem', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 900.000000 760.000000"
    >
      <g transform="translate(0.000000,760.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
        <path
          d="M8069 7427 c-1202 -803 -2087 -1611 -2719 -2482 -663 -914 -1034
-1951 -1007 -2814 11 -365 87 -680 222 -915 25 -44 45 -90 45 -103 0 -28 75
-156 130 -223 20 -25 60 -79 88 -120 56 -82 290 -320 314 -320 9 0 21 -7 29
-16 7 -9 27 -23 45 -32 86 -46 122 -66 149 -87 102 -76 331 -148 571 -180 508
-67 1023 80 1419 405 86 71 233 222 301 310 196 251 327 566 375 893 16 112
13 377 -5 492 -69 427 -256 782 -568 1081 -172 164 -355 283 -573 373 -193 80
-382 124 -590 137 -58 4 -105 8 -105 10 0 2 26 61 58 131 314 689 914 1388
1812 2109 171 138 536 409 641 476 48 31 117 85 155 120 38 36 86 81 108 100
l39 36 -334 387 c-184 214 -339 390 -345 392 -6 2 -120 -70 -255 -160z"
        />
        <path
          d="M3815 7422 c-1289 -844 -2302 -1787 -2932 -2732 -203 -303 -419 -705
-540 -1005 -339 -833 -423 -1649 -232 -2234 38 -114 88 -232 135 -311 19 -32
34 -70 34 -83 0 -27 84 -171 130 -224 16 -18 48 -61 71 -95 43 -66 305 -338
325 -338 6 0 19 -9 29 -20 10 -11 26 -22 37 -25 20 -7 129 -71 189 -111 19
-13 75 -40 123 -60 328 -134 799 -155 1166 -53 698 195 1240 811 1346 1530 23
159 16 461 -15 608 -68 321 -219 625 -436 873 -310 356 -804 603 -1253 625
-67 3 -124 9 -127 13 -2 3 22 67 54 142 157 367 392 733 715 1114 153 180 587
611 798 791 284 244 683 554 883 686 115 76 175 124 269 211 l89 83 -68 74
c-148 162 -550 596 -579 626 l-31 33 -180 -118z"
        />
      </g>
    </svg>
  );
};

export const SettingIcon = ({ width = '3rem', height = '3rem', className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" width={width} height={height} className={className}>
      <path
        d="M58.0959473,39.0869141c0.4135742-0.1328125,0.6938477-0.5175781,0.6938477-0.9521484v-6.2402344
	c0-0.4345703-0.2807617-0.8193359-0.6943359-0.9521484l-5.8862305-1.8876953
	c-0.2607422-0.7246094-0.527832-1.3701172-0.8081055-1.9541016l2.8291016-5.5195313
	c0.1977539-0.3857422,0.1240234-0.8554688-0.1821289-1.1621094l-4.4101563-4.4199219
	c-0.3066406-0.3085938-0.7758789-0.3808594-1.1621094-0.1845703l-5.5205078,2.8154297
	c-0.6206055-0.3095703-1.2827148-0.5859375-1.9785156-0.8261719l-1.8842773-5.8935547
	c-0.1328125-0.4140625-0.5175781-0.6953125-0.9526367-0.6953125h-6.2397461c-0.4335938,0-0.8178711,0.2792969-0.9516602,0.6923828
	l-1.9047852,5.890625c-0.5966797,0.2070313-1.2832031,0.4716797-1.9794922,0.8193359l-5.4868164-2.8222656
	c-0.3876953-0.1982422-0.8583984-0.1240234-1.1655273,0.1835938l-4.4199219,4.4306641
	c-0.3056641,0.3066406-0.3793945,0.7744141-0.1826172,1.1601563l2.7973633,5.484375
	c-0.3144531,0.6396484-0.5869141,1.3017578-0.8139648,1.9775391l-5.8696289,1.8916016
	c-0.4121094,0.1328125-0.6918945,0.515625-0.6933594,0.9492188l-0.0195313,6.2402344
	c-0.0014648,0.4345703,0.277832,0.8193359,0.690918,0.9541016l5.8930664,1.9140625
	c0.2246094,0.6328125,0.4916992,1.2705078,0.8178711,1.9541016l-2.8125,5.5048828
	c-0.1967773,0.3857422-0.1235352,0.8535156,0.1821289,1.1601563l4.3999023,4.4199219
	c0.3061523,0.3076172,0.7758789,0.3857422,1.1621094,0.1855469l5.5175781-2.8095703
	c0.6499023,0.3183594,1.2954102,0.5888672,1.9541016,0.8183594l1.9140625,5.8808594
	c0.1337891,0.4121094,0.5175781,0.6904297,0.9506836,0.6904297h6.2397461c0.4331055,0,0.8168945-0.2783203,0.9506836-0.6904297
	l1.9160156-5.8847656c0.6914063-0.2451172,1.3354492-0.5107422,1.9482422-0.8046875l5.5107422,2.8105469
	c0.3876953,0.1962891,0.8540039,0.1220703,1.1611328-0.1826172l4.409668-4.4003906
	c0.3061523-0.3056641,0.3813477-0.7744141,0.1850586-1.1601563l-2.8071289-5.5322266
	c0.2939453-0.5927734,0.5625-1.2402344,0.8129883-1.9589844L58.0959473,39.0869141z M49.3781738,43.3867188l2.7114258,5.3427734
	l-3.3818359,3.375l-5.3129883-2.7099609c-0.2880859-0.1484375-0.6274414-0.1464844-0.9135742,0.0019531
	c-0.7924805,0.4101563-1.6445313,0.7626953-2.6040039,1.078125c-0.3022461,0.0996094-0.5395508,0.3378906-0.6376953,0.640625
	l-1.8457031,5.6699219h-4.7875977l-1.8457031-5.6699219c-0.0996094-0.3066406-0.3417969-0.546875-0.6494141-0.6435547
	c-0.8764648-0.2773438-1.7231445-0.6318359-2.5883789-1.0839844c-0.2861328-0.1484375-0.6279297-0.1513672-0.9165039-0.0039063
	l-5.3212891,2.7089844l-3.3740234-3.3896484l2.7094727-5.3037109c0.1445313-0.2832031,0.1459961-0.6181641,0.0039063-0.9023438
	c-0.4750977-0.9501953-0.8222656-1.7822266-1.0927734-2.6201172c-0.0986328-0.3046875-0.3374023-0.5439453-0.6425781-0.6435547
	l-5.6762695-1.84375l0.0146484-4.7851563l5.6694336-1.8271484c0.3134766-0.1015625,0.5571289-0.3505859,0.6513672-0.6660156
	c0.2675781-0.8945313,0.625-1.7636719,1.0639648-2.5859375c0.1538086-0.2880859,0.1572266-0.6337891,0.0083008-0.9248047
	l-2.699707-5.2929688l3.3911133-3.3994141l5.3007813,2.7265625c0.2983398,0.1513672,0.6523438,0.1464844,0.9443359-0.015625
	c0.7163086-0.3994141,1.4916992-0.7236328,2.59375-1.0839844c0.3041992-0.0986328,0.5424805-0.3378906,0.6411133-0.6425781
	l1.8359375-5.6777344h4.7822266l1.8173828,5.6845703c0.0996094,0.3105469,0.34375,0.5527344,0.6542969,0.6494141
	c0.9379883,0.2929688,1.809082,0.6582031,2.5888672,1.0839844c0.2900391,0.1572266,0.6396484,0.1630859,0.9335938,0.0126953
	l5.3320313-2.71875l3.3823242,3.3896484l-2.7285156,5.3222656c-0.1479492,0.2890625-0.1464844,0.6318359,0.0039063,0.9189453
	c0.3818359,0.7314453,0.7382813,1.5878906,1.0893555,2.6191406c0.1020508,0.2998047,0.3398438,0.5332031,0.6411133,0.6298828
	l5.6655273,1.8173828V37.40625l-5.6757813,1.8261719c-0.3066406,0.0986328-0.5463867,0.3388672-0.6455078,0.6445313
	c-0.3237305,1.0029297-0.6757813,1.8466797-1.0761719,2.5771484C49.2341309,42.7441406,49.2287598,43.0927734,49.3781738,43.3867188
	z"
      ></path>
      <path
        d="M36.2541504,25.5644531c-5.2006836-0.6933594-9.9995117,2.9775391-10.6933594,8.1796875
	c-0.3364258,2.5244141,0.3271484,5.0292969,1.8691406,7.0517578c1.5439453,2.0244141,3.7836914,3.3251953,6.3076172,3.6611328
	c0.425293,0.0566406,0.8476563,0.0839844,1.265625,0.0839844c4.7050781,0,8.8120117-3.4921875,9.449707-8.2802734
	C45.1462402,31.0585938,41.4685059,26.2597656,36.2541504,25.5644531z M42.470459,35.9970703
	c-0.5483398,4.1201172-4.3393555,7.0273438-8.4692383,6.4775391c-1.9926758-0.265625-3.7617188-1.2919922-4.9804688-2.8916016
	c-1.21875-1.5986328-1.7436523-3.578125-1.4775391-5.5751953c0.5029297-3.7744141,3.7402344-6.5273438,7.4492188-6.5273438
	c0.3295898,0,0.6625977,0.0214844,0.9975586,0.0664063C40.111084,28.0966797,43.0178223,31.8867188,42.470459,35.9970703z"
      ></path>
    </svg>
  );
};

export const SendIcon = ({ width = '1.8rem', height = '1.8rem', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 487 450.9"
    fill="#fff"
  >
    <g>
      <path
        d="M485.3,0.1L0.6,248.2c-0.9,0.5-0.8,1.8,0.2,2.1l167.4,53.1c0.4,0.1,0.8,0,1.1-0.2l207.8-186.6c1.1-0.9,2.5,0.5,1.6,1.6
		L208.9,314.9c-0.5,0.6-0.3,1.6,0.5,1.8l185.7,57.4c0.6,0.2,1.3-0.2,1.4-0.8L486.9,1.4C487.2,0.5,486.2-0.3,485.3,0.1z"
      />
      <path
        d="M273.4,358.8l-59.4-18c-0.7-0.2-1.5,0.3-1.5,1.1l1.3,108c0,1.1,1.5,1.5,2.1,0.6l58.1-89.9
		C274.4,359.9,274.1,359,273.4,358.8z"
      />
    </g>
  </svg>
);

export const PhoneIcon = ({ width = '2.2rem', height = '2.2rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.353 1.5C17.054 1.911 19.978 4.831 20.393 8.532"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.353 5.04303C15.124 5.38703 16.508 6.77203 16.853 8.54303"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0315 11.4724C14.0205 15.4604 14.9254 10.8467 17.4653 13.3848C19.9138 15.8328 21.3222 16.3232 18.2188 19.4247C17.8302 19.737 15.3613 23.4943 6.68447 14.8197C-1.99341 6.144 1.76157 3.67244 2.07394 3.28395C5.18377 0.173846 5.66682 1.58938 8.11539 4.03733C10.6541 6.5765 6.04254 7.48441 10.0315 11.4724Z"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InfoIcon = ({ width = '1.6rem', height = '1.6rem', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <circle cx="7" cy="7" r="7" stroke="#9A9A9A" transform="translate(1 1)" />
      <path fill="#9A9A9A" d="M7 4h2v2H7zM7 7h2v5H7z" />
    </g>
  </svg>
);

export const AddressIcon = ({ width = '1.8rem', height = '2rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 8.50051C11.5 7.11924 10.3808 6 9.00051 6C7.61924 6 6.5 7.11924 6.5 8.50051C6.5 9.88076 7.61924 11 9.00051 11C10.3808 11 11.5 9.88076 11.5 8.50051Z"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99951 19C7.80104 19 1.5 13.8984 1.5 8.56329C1.5 4.38664 4.8571 1 8.99951 1C13.1419 1 16.5 4.38664 16.5 8.56329C16.5 13.8984 10.198 19 8.99951 19Z"
      stroke="#1A162E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NoteIcon = ({ width = '2.2rem', height = '2.2rem', className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M13.5,20 C14.3284271,20 15,19.3284271 15,18.5 C15,17.1192881 16.1192881,16 17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,11.5 C19,11.2238576 19.2238576,11 19.5,11 C19.7761424,11 20,11.2238576 20,11.5 L20,14.5 C20,18.0898509 17.0898509,21 13.5,21 L6.5,21 C5.11928813,21 4,19.8807119 4,18.5 L4,5.5 C4,4.11928813 5.11928813,3 6.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 C13,3.77614237 12.7761424,4 12.5,4 L6.5,4 C5.67157288,4 5,4.67157288 5,5.5 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L13.5,20 L13.5,20 Z M15.7913481,19.5014408 C16.9873685,18.9526013 17.9526013,17.9873685 18.5014408,16.7913481 C18.1948298,16.9255432 17.8561101,17 17.5,17 C16.6715729,17 16,17.6715729 16,18.5 C16,18.8561101 15.9255432,19.1948298 15.7913481,19.5014408 L15.7913481,19.5014408 Z M18,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L18,7 L18,9.5 C18,9.77614237 17.7761424,10 17.5,10 C17.2238576,10 17,9.77614237 17,9.5 L17,7 L14.5,7 C14.2238576,7 14,6.77614237 14,6.5 C14,6.22385763 14.2238576,6 14.5,6 L17,6 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,6 Z M8.5,9 C8.22385763,9 8,8.77614237 8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L12.5,8 C12.7761424,8 13,8.22385763 13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L8.5,9 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,11.2238576 16,11.5 C16,11.7761424 15.7761424,12 15.5,12 L8.5,12 Z M8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 L13.5,14 C13.7761424,14 14,14.2238576 14,14.5 C14,14.7761424 13.7761424,15 13.5,15 L8.5,15 Z"></path>{' '}
    </g>
  </svg>
);

export const ReloadIcon = ({ width = '2rem', height = '2rem', className }) => (
  <svg
    fill="#000000"
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 489.711 489.711"
  >
    <g>
      <g>
        <path
          d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13
			c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13
			c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1
			c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"
        />
        <path
          d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7
			c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8
			c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1
			c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4
			C456.056,357.911,481.656,274.811,462.456,195.511z"
        />
      </g>
    </g>
  </svg>
);

export const TagsIcon = ({ width = '2rem', height = '2rem', className }) => (
  <svg
    fill="#4d8b55"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 542.183 542.183"
  >
    <g>
      <g>
        <path
          d="M432.544,310.636c0-9.897-3.521-18.559-10.564-25.984L217.844,80.8c-7.232-7.238-16.939-13.374-29.121-18.416
			c-12.181-5.043-23.319-7.565-33.407-7.565H36.545c-9.896,0-18.464,3.619-25.694,10.848C3.616,72.9,0,81.466,0,91.365v118.771
			c0,10.088,2.519,21.219,7.564,33.404c5.046,12.185,11.187,21.792,18.417,28.837L230.12,476.799
			c7.043,7.043,15.608,10.564,25.694,10.564c9.898,0,18.562-3.521,25.984-10.564l140.186-140.47
			C429.023,329.284,432.544,320.725,432.544,310.636z M117.204,172.02c-7.139,7.138-15.752,10.709-25.841,10.709
			c-10.085,0-18.698-3.571-25.837-10.709c-7.139-7.139-10.705-15.749-10.705-25.837c0-10.089,3.566-18.702,10.705-25.837
			c7.139-7.139,15.752-10.71,25.837-10.71c10.089,0,18.702,3.571,25.841,10.71c7.135,7.135,10.707,15.749,10.707,25.837
			C127.91,156.271,124.339,164.881,117.204,172.02z"
        />
        <path
          d="M531.612,284.655L327.473,80.804c-7.23-7.238-16.939-13.374-29.122-18.417c-12.177-5.042-23.313-7.564-33.402-7.564
			h-63.953c10.088,0,21.222,2.522,33.402,7.564c12.185,5.046,21.892,11.182,29.125,18.417l204.137,203.851
			c7.046,7.423,10.571,16.084,10.571,25.981c0,10.089-3.525,18.647-10.571,25.693L333.469,470.519
			c5.718,5.9,10.759,10.182,15.133,12.847c4.38,2.666,9.996,3.998,16.844,3.998c9.903,0,18.565-3.521,25.98-10.564l140.186-140.47
			c7.046-7.046,10.571-15.604,10.571-25.693C542.179,300.739,538.658,292.078,531.612,284.655z"
        />
      </g>
    </g>
  </svg>
);

export const ChatIcon = ({ width = '2rem', height = '2rem', className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M919.192216 976.840649a42.620541 42.620541 0 0 1-21.919135-6.088649l-185.094919-110.675027A560.95827 560.95827 0 0 1 512 896.249081c-274.681081 0-498.162162-192.982486-498.162162-430.190703C13.837838 228.850162 237.318919 35.867676 512 35.867676S1010.162162 228.850162 1010.162162 466.058378c0 104.64173-42.952649 203.637622-121.66227 281.821406l70.379243 168.683243c7.195676 17.269622 2.601514 37.251459-11.374703 49.567135-8.025946 7.084973-18.127568 10.710486-28.312216 10.710487z m-203.277838-208.45319c7.610811 0 15.193946 2.048 21.919136 6.088649l91.108324 54.438054-31.494919-75.443892a43.699892 43.699892 0 0 1 11.623784-49.816216c74.170811-64.345946 115.020108-148.729081 115.020108-237.595676C924.090811 276.756757 739.217297 122.713946 512 122.713946S99.909189 276.756757 99.909189 466.058378c0 189.301622 184.873514 343.344432 412.090811 343.344433 65.785081 0 128.719568-12.647784 187.142919-37.583568 5.369081-2.297081 11.07027-3.431784 16.771459-3.431784zM260.953946 470.154378a56.32 56.32 0 0 1 56.347676-56.015567 56.347676 56.347676 0 0 1 55.794162 56.596757c0 31.135135-24.908108 56.430703-55.794162 56.569081A56.32 56.32 0 0 1 260.981622 471.316757v-1.134703z m186.478703 0c0 31.965405 25.710703 57.897514 57.399351 57.897514a57.648432 57.648432 0 0 0 57.371676-57.897514 57.648432 57.648432 0 0 0-57.371676-57.897513 57.648432 57.648432 0 0 0-57.399351 57.897513z m186.506378 0a56.32 56.32 0 0 1 56.347676-56.015567 56.347676 56.347676 0 0 1 55.794162 56.596757c0 31.135135-24.908108 56.430703-55.794162 56.569081a56.32 56.32 0 0 1-56.347676-56.015568v-1.134703z"
      fill="#ffffff"
    />
  </svg>
);

export const BrandIcon = ({ width = '2rem', height = '2rem', className }) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <g>
      <g>
        <path
          fill="#000000"
          stroke="#000000"
          strokeWidth="5"
          d="M246,128c0-65.2-52.8-118-118-118C62.8,10,10,62.8,10,128c0,65.2,52.8,118,118,118c26.8,0,51.2-9.1,71-24.1c0.8-0.9,1.3-2.1,1.3-3.4c0-2.9-2.3-5.2-5.2-5.2c-1.5,0-2.8,0.6-3.8,1.6h0c-17.7,13-39.5,20.8-63.2,20.8c-59,0-106.9-47.8-106.9-106.9C21.2,69.8,69.1,22,128.1,22c59,0,106.8,47.9,106.8,106.9c0,18-4.2,35-12.1,49.9v0.1c-0.1,0.5-0.3,1-0.3,1.5c0,2.9,2.4,5.2,5.2,5.2c2.3,0,4.1-1.4,4.9-3.4v0C241,165.9,246,147.5,246,128L246,128z"
        />
        <path
          fill="#000000"
          stroke="#000000"
          strokeWidth="4"
          d="M88,193.9l-1.3-0.1c-2-0.3-3.7-1-5.3-2c-1.6-1.1-2.8-2.6-3.7-4.3c-0.9-1.9-1.2-4-0.7-6.2l0.7-2.9c0.3-1.4,0.7-3,1.2-4.9c0.5-1.9,1-4.2,1.6-6.6l5.6-22.8l-0.7-0.6c-4-3.4-7.6-6.6-11-9.4l-5.4-4.6c-1.8-1.5-3.4-2.9-4.9-4.2c-1.3-1.2-2.5-2.1-3.3-2.9c-1.3-1.1-2-1.7-2-1.8c-1.1-1-2.4-2.5-3.5-4.2c-1.5-2.4-2.1-5.1-1.7-7.8c0.4-2.6,1.5-4.9,3.4-6.6c1.6-1.4,3.5-2.4,5.6-2.8l39.3-3.6l14.3-33.4c0.8-2,2-3.9,3.5-5.5c1.5-1.5,4.1-3.4,8.4-3.4c2,0,3.8,0.5,5.4,1.4c1.3,0.8,2.3,1.7,3.2,2.7c0.7,0.9,1.3,1.7,1.8,2.5l0.7,1.3l14.3,34l38.7,3.6c3.7,0.8,5.6,1.8,7.1,3c1.4,1.2,3.2,3.5,3.2,7.5c0,2.6-0.8,5-2.5,7.1c-0.8,1-1.9,2.2-3.1,3.7L170.7,144c1.3,5.9,2.4,11.3,3.4,16.2c0.6,2.4,1.1,4.9,1.5,7.5l2.3,11.8c0.2,1.2,0.4,2,0.5,2.3c0.8,4.9-0.8,8.5-4.1,10.3c-2.8,1.5-5.5,2.6-10.5,0.8l-2.2-0.8l-33.4-19.4c-5.3,3.1-10.3,6-14.7,8.5l-6.8,3.8c-2.2,1.3-4.2,2.4-6,3.5c-1.9,1.1-3.5,2.1-4.9,2.8c-1.7,0.9-2.8,1.4-3.3,1.7C91.3,193.5,89.7,193.9,88,193.9z M127.7,65.3c0.1,0.2-0.2,0.8-0.5,1.5l-17.2,40l-44.1,4c0.4,0.5,0.8,0.9,1.3,1.4l1.3,1.2c0.9,0.7,2.1,1.8,3.6,3.1l10.2,8.7c3.3,2.8,7,5.9,11,9.4l6.2,5.3l-7.3,29.9c-0.6,2.4-1.1,4.5-1.5,6.4c-0.5,2-0.9,3.7-1.3,5.2c0.3-0.1,0.5-0.3,0.8-0.4c1.2-0.7,2.7-1.6,4.5-2.6c1.9-1.1,3.9-2.3,6.2-3.6l6.8-3.8c5.2-3,11.1-6.4,17.5-10.2l3-1.8l38.1,22.2l-2.2-11.2c-0.5-2.4-0.9-4.8-1.4-7.2c-1.2-5.9-2.6-12.4-4.1-19.6l-0.7-3.3l30.8-27.7c0.3-0.4,0.7-0.9,1.1-1.4l-44.3-4.1l-16.7-39.9"
        />
      </g>
    </g>
  </svg>
);

export const ViewIcon = ({ width = '2.2rem', height = '2.2rem', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="#65391c"
    strokeWidth="5"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 282.68"
  >
    <path d="M3.14 132.9c14.51-17.53 29.53-33.35 44.94-47.39 60.17-54.78 127.69-84 197.43-85.45 69.61-1.46 141.02 24.79 209.14 80.95 18.45 15.21 36.6 32.54 54.3 52 3.82 4.19 4.02 10.42.78 14.81-19.73 27.91-41.98 51.4-65.97 70.56-53.57 42.77-115.96 63.9-179.2 64.29-63.05.39-126.84-19.87-183.44-59.83-28.31-20-54.85-44.93-78.58-74.67-3.65-4.59-3.29-11.1.6-15.27zM256 83.24c32.09 0 58.1 26.01 58.1 58.1s-26.01 58.1-58.1 58.1-58.1-26.01-58.1-58.1c0-5.97.9-11.74 2.57-17.16 4.25 11.15 15.04 19.07 27.68 19.07 16.35 0 29.61-13.26 29.61-29.61 0-12.7-7.98-23.52-19.2-27.73 5.5-1.73 11.36-2.67 17.44-2.67zm107.24-33.52a141.453 141.453 0 0 1 23.1 37.7c6.92 16.67 10.74 34.9 10.74 53.92 0 19.03-3.82 37.26-10.73 53.94a141.479 141.479 0 0 1-30.6 45.8l-1.92 1.89c26.4-9.83 51.79-24.09 75.37-42.91 20.12-16.07 38.96-35.49 55.99-58.27-15-15.93-30.16-30.18-45.38-42.73-25.22-20.8-50.84-37.2-76.57-49.34zm-212.08 185.9c-10.65-11.81-19.33-25.44-25.5-40.32a140.518 140.518 0 0 1-10.74-53.96c0-19.01 3.81-37.22 10.72-53.87 6.85-16.52 16.75-31.46 28.96-44.1-31.5 13.33-61.97 33.25-90.76 59.44-12.7 11.57-25.04 24.3-36.95 38.17 20.74 24.71 43.54 45.64 67.69 62.71 18.19 12.84 37.15 23.5 56.58 31.93zM300.95 32.58c-13.78-5.71-28.98-8.88-44.94-8.88-15.94 0-31.12 3.17-44.93 8.9-14.34 5.95-27.32 14.73-38.23 25.64-10.88 10.89-19.64 23.85-25.6 38.2-5.71 13.79-8.88 28.97-8.88 44.9 0 15.96 3.17 31.17 8.9 44.98a117.654 117.654 0 0 0 25.58 38.19c10.86 10.84 23.84 19.6 38.24 25.57 13.8 5.72 28.98 8.88 44.92 8.88 15.95 0 31.15-3.17 44.96-8.88 14.36-5.93 27.32-14.7 38.2-25.57 10.88-10.88 19.64-23.84 25.57-38.16 5.72-13.85 8.89-29.05 8.89-45.01 0-15.95-3.17-31.14-8.89-44.95-5.93-14.37-14.69-27.33-25.57-38.21-10.86-10.86-23.84-19.63-38.22-25.6z" />
  </svg>
);
