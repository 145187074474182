import classNames from 'classnames/bind';
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import styles from './WalletRecharge.module.scss';

import hostname from '~/utils/http';
import images from '~/assets/images';
import { CopyIcon } from '~/components/Icons';
import formatCurrency from '~/utils/formatCurrency';

const cx = classNames.bind(styles);

function WalletRecharge({ userInfo }) {
  const { t } = useTranslation();
  const socket = io(hostname, {
    query: { userId: userInfo?._id },
  });

  socket.on('rechargeSuccess', (data) => {
    toast.success(t('topUp.desc06') + formatCurrency(data.amount) + ' VND');
  });

  return (
    <div className={cx('wallet')}>
      <h4 className={cx('wallet__title')}>{t('topUp.title01')}</h4>

      <div title="Copy" className={cx('wallet__transfer-content-container')}>
        <div
          className={cx('wallet__transfer-content-value')}
          onClick={(e) => {
            const text = e.target.innerText;
            navigator.clipboard.writeText(text);
            toast.success(t('authTwinSetup.toast.copySuccess'));
          }}
        >
          {userInfo?.username} <CopyIcon className={cx('wallet__copy-icon')} />
        </div>
      </div>

      <div className={cx('wallet__logo-bank-container')}>
        <img src={images.logoTpBank} alt="MoccaCafe TPbank" />
      </div>

      <div className={cx('wallet__qr-wrapper')}>
        <div className={cx('wallet__qr-info')}>
          <div className={cx('wallet__qr-info-row')}>
            <span className={cx('wallet__qr-info-label')}>{t('topUp.title02')}</span>
            <span className={cx('wallet__qr-info-value')}>00005572823</span>
          </div>

          <div className={cx('wallet__qr-info-row')}>
            <span className={cx('wallet__qr-info-label')}>{t('topUp.title03')}</span>
            <span className={cx('wallet__qr-info-value', 'wallet__qr-info-value--green')}>NGUYEN NHU CONG</span>
          </div>

          <div className={cx('wallet__qr-info-row')}>
            <span className={cx('wallet__qr-info-label')}>{t('topUp.title04')}</span>
            <span className={cx('wallet__qr-info-value')}>1 VNĐ = 1 MoccaCafe coin</span>
          </div>

          <div className={cx('wallet__qr-info-row')}>
            <span className={cx('wallet__qr-info-label')}>{t('topUp.title05')}</span>
            <span className={cx('wallet__qr-info-value')}>10.000 VNĐ</span>
          </div>

          <div className={cx('wallet__qr-container')}>
            <img
              src={`https://img.vietqr.io/image/970423-00005572823-compact.jpg?addInfo=${userInfo?.username}`}
              alt="MoccaCafe"
            />
          </div>
        </div>
      </div>

      <div className={cx('wallet__note-container')}>
        <h4 className={cx('wallet__note-label')}>{t('topUp.title06')}</h4>
        <p className={cx('wallet__note-desc')}>
          <span>-</span>
          {t('topUp.desc01')}
        </p>
        <div className={cx('wallet__note-desc')}>
          <span> -</span> <p>{t('topUp.desc02')}</p>
        </div>
        <div className={cx('wallet__note-desc')}>
          <span> -</span>{' '}
          <p>
            {t('topUp.desc03')}{' '}
            <a href="https://www.facebook.com/messages/t/274451679080721" target="blank">
              {t('topUp.desc04')}
            </a>{' '}
            {t('topUp.desc05')}
          </p>
        </div>
      </div>
    </div>
  );
}
export default WalletRecharge;
