import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import styles from './ProductDetail.module.scss';

import images from '~/assets/images';
import Button from '~/components/Button';
import BreadCrumb from '~/components/BreadCrumb';
import LoadingStart from '~/components/LoadingStart';
import { BagIcon, MinusIcon, PlusIcon, TagsIcon, StoreIcon, ChatIcon, BrandIcon } from '~/components/Icons';
import { useChatContext } from '~/Layouts/components/Chats/ChatMessage/context/ChatContext';
import { getLocalStorageItem } from '~/utils/localStorage';
import { getRestaurantDetail } from '~/services/restaurantService';
import { addProductToCart } from '~/services/cartService';

const cx = classNames.bind(styles);

function ProductDetail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.restaurant.loading);
  const reduxData = useSelector((prop) => prop.restaurant);
  const productIDSelected = JSON.parse(sessionStorage.getItem('productIDSelected'));

  const [classifies, setClassifies] = [['100g', '200g', '500g']];
  const [shopData, setShopData] = useState({});
  const [dataProduct, setDataProduct] = useState({});

  const [classify, setClassify] = useState('');
  const [quantity, setQuantity] = useState(1);

  const handleMinusQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handlePlusQuantity = () => {
    if (quantity === 10) {
      toast.info(t('quantity-drawer.toast.invalid-quantity'));
      return;
    }
    setQuantity(quantity + 1);
  };

  const handleAddToCart = () => {
    if (getLocalStorageItem('user')) {
      dispatch(addProductToCart({ product: dataProduct?._id, quantity: quantity })).then((result) => {
        if (result.payload.code === 200) {
          toast.success(result.payload.message);
          setQuantity(1);
          setClassify(classifies?.[0]);
        } else {
          toast.error(result.payload.message);
        }
      });
    } else {
      toast.info(t('quantity-drawer.toast.unauthorized'));
    }
  };

  const handleSeeShop = () => {
    const restaurantSelected = sessionStorage.getItem('restaurantSelected')
      ? JSON.parse(sessionStorage.getItem('restaurantSelected'))
      : null;

    if (restaurantSelected && restaurantSelected.slug) {
      navigate(`/restaurant/${restaurantSelected.slug}`);
    }
  };

  const { openModal, addConversation } = useChatContext();
  const handleOpenChatModal = async () => {
    const user = await JSON.parse(localStorage.getItem('user'));
    if (!user) {
      toast.warning(t('errors.err12'));
      return navigate('/auth/login');
    }

    addConversation(reduxData.restaurantDetail);

    openModal();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const restaurantId = JSON.parse(sessionStorage.getItem('restaurantIDSelected'));
    dispatch(getRestaurantDetail({ restaurantId: restaurantId })).then((result) => {
      if (result.payload.code === 200) {
        setShopData(result.payload.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shopData && shopData.shop && shopData.shop.categories) {
      shopData.shop.categories.map((category) =>
        category.products.map((product) => {
          if (product._id === productIDSelected) {
            setDataProduct(product);
          }
          return product;
        }),
      );
    }
  }, [shopData, productIDSelected]);

  return (
    <>
      <div className={cx('bread-crumb')}>
        <div className={cx('container gx-5')}>
          <BreadCrumb />
        </div>
      </div>
      <div className={cx('product-detail')}>
        <div className={cx('container gx-5')}>
          <div className={cx('product-detail__container')}>
            <div className={cx('row')}>
              <div className={cx('col-12 col-xxl-5 col-xl-5 col-lg-6 col-md-12')}>
                <div className={cx('preview')}>
                  <div className={cx('preview__item')}>
                    <div className={cx('preview__item-wrap')}>
                      <img src={dataProduct?.image} className={cx('preview__item-img')} alt={dataProduct?.name} />
                    </div>
                  </div>
                  <div className={cx('preview__list')}></div>
                </div>
              </div>
              <div className={cx('col-12 col-xxl-7 col-xl-7 col-lg-6 col-md-12')}>
                <div className={cx('product-detail__info')}>
                  <h1 className={cx('product-detail__heading')}>{dataProduct?.name}</h1>

                  <div className={cx('row')}>
                    <div className={cx('col-12 col-xxl-5 col-xl-6 col-lg-12 col-md-12')}>
                      <div className={cx('product-detail__filter')}>
                        <h4 className={cx('filter__title')}>{t('productDetail.title01')}</h4>
                        <div className={cx('filter__wrapper')}>
                          {classifies?.map((classifyOption, index) => (
                            <div
                              onClick={() => setClassify(classifyOption)}
                              className={cx(
                                'filter__weight',
                                classifyOption === classify ? 'filter__weight--active' : '',
                              )}
                              key={index}
                            >
                              {classifyOption}
                            </div>
                          ))}
                          {classifies.length === 0 && <p className={cx('filter__empty')}>{t('checkout.desc08')}</p>}
                        </div>

                        <h4 className={cx('filter__title')}>{t('productDetail.title02')}</h4>
                        <div className={cx('filter__wrapper')}>
                          <Button onClick={handleMinusQuantity} action outline leftIcon={<MinusIcon />}></Button>
                          <span className={cx('filter__quantity')}>{quantity}</span>
                          <Button onClick={handlePlusQuantity} action outline leftIcon={<PlusIcon />}></Button>
                        </div>
                      </div>
                    </div>
                    <div className={cx('col-12 col-xxl-7 col-xl-6 col-lg-12 col-md-12')}>
                      <div className={cx('filter__wrapper')}>
                        <div className={cx('product-detail__status')}>
                          <BrandIcon />
                          <div className={cx('product-detail__status-info')}>
                            <span className={cx('product-detail__status-title')}>{t('productDetail.title05')}</span>
                            <span className={cx('product-detail__status-desc', 'brand')}>Mocca Cafe</span>
                          </div>
                        </div>
                      </div>

                      <div className={cx('filter__wrapper')}>
                        <div className={cx('product-detail__status')}>
                          <BagIcon />
                          <div className={cx('product-detail__status-info')}>
                            <span className={cx('product-detail__status-title')}>{t('productDetail.title03')}</span>
                            <span className={cx('product-detail__status-desc', 'in_stock')}>
                              {t('productDetail.status01')}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className={cx('filter__wrapper')}>
                        <div className={cx('product-detail__price-wrapper')}>
                          <div className={cx('product-detail__price-info')}>
                            <TagsIcon />
                            <span className={cx('product-detail__price')}>{`${(dataProduct.price
                              ? dataProduct.price
                              : 0
                            ).toLocaleString('vi-VN')} ₫`}</span>
                          </div>
                          <span className={cx('product-detail__total-price')}>{`${(dataProduct.price
                            ? dataProduct?.price * quantity
                            : 0
                          ).toLocaleString('vi-VN')} ₫`}</span>
                          <Button onClick={handleAddToCart} order primary>
                            {t('quantity-drawer.add-to-cart')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('row mt-5')}>
              <div className={cx('shop')}>
                <img
                  src={shopData?.shop?.avatar || images.avatarDefault}
                  className={cx('shop__avatar')}
                  alt={shopData?.shop?.fullname}
                />
                <div className={cx('shop__info')}>
                  <h4 className={cx('shop__name')}>{shopData?.shop?.fullname}</h4>
                  <div className={cx('shop__action')}>
                    <Button
                      onClick={handleOpenChatModal}
                      leftIcon={<ChatIcon className={cx('shop__icon')} />}
                      shopAction
                      primary
                    >
                      {t('button.btn21')}
                    </Button>
                    <Button
                      onClick={handleSeeShop}
                      leftIcon={<StoreIcon className={cx('shop__icon')} />}
                      shopAction
                      outline
                      primary
                    >
                      {t('button.btn22')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('row mt-5')}>
              <div className={cx('col-12 col-xxl-10 col-xl-10 col-lg-10 col-md-12')}>
                <div className={cx('product-detail__desc')}>
                  <h4>{t('productDetail.title04')}</h4>
                  <p>{dataProduct?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && <LoadingStart />}
    </>
  );
}

export default ProductDetail;
