import classNames from 'classnames/bind';

import styles from './LoadingStart.module.scss';

const cx = classNames.bind(styles);

function LoadingStart() {
  return (
    <div className={cx('modal-loading')}>
      <div className={cx('modal-loading__content')}>
        <div className={cx('loading-start')}>
          <span className={cx('loading-start__1')}>
            <span className={cx('loading-start__2')}>
              <span className={cx('loading-start__3')}>
                <span className={cx('loading-start__4')}></span>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LoadingStart;
