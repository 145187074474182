import { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './ListSlider.module.scss';

import RestaurantCard from '~/components/RestaurantCard';
import { getRestaurantsForListSlider } from '~/services/restaurantService';

const cx = classNames.bind(styles);

function NextArrow(props) {
  const { className, style, onClick, customClass } = props;

  return <div className={`${className} ${customClass}`} style={{ ...style, display: 'block' }} onClick={onClick}></div>;
}

function PrevArrow(props) {
  const { className, style, onClick, customClass } = props;

  return <div className={`${className} ${customClass}`} style={{ ...style, display: 'block' }} onClick={onClick}></div>;
}

function ListSlider() {
  const dispatch = useDispatch();
  const reduxData = useSelector((prop) => prop.restaurant);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    arrow: false,
    rtl: true,
    className: cx('list-slider'),
    nextArrow: <NextArrow customClass={cx('next-arrow')} />,
    prevArrow: <PrevArrow customClass={cx('prev-arrow')} />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: <></>,
          prevArrow: <></>,
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (reduxData.listSlider?.length > 0) {
      setHasData(true);
      setData(reduxData.listSlider);
    } else {
      dispatch(getRestaurantsForListSlider({ limit: 5, page: 1 })).then((result) => {
        if (result.payload.code === 200) {
          setData(result.payload.data.shops);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx('list-promo')}>
      {data.length > 0 && (
        <div className={cx({ 'list-wrapper': !hasData })}>
          <Slider {...settings}>
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <div key={index} className={cx('item')}>
                    <RestaurantCard data={item} />
                  </div>
                );
              })}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default memo(ListSlider);
