import { createSlice } from '@reduxjs/toolkit';
import { getCities, getDistricts, getWards, gHNExpressFee } from '~/services/ghnExpressService';

const expressServiceSlice = createSlice({
  name: 'expressService',
  initialState: {
    citiesLoading: null,
    districtsLoading: null,
    wardsLoading: null,
    shippingFeeLoading: null,
    error: null,
  },

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCities.pending, (state) => {
        state.citiesLoading = true;
        state.error = null;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.citiesLoading = false;
        state.error = null;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.citiesLoading = false;
        state.error = action.payload;
      })
      .addCase(getDistricts.pending, (state) => {
        state.districtsLoading = true;
        state.error = null;
      })
      .addCase(getDistricts.fulfilled, (state, action) => {
        state.districtsLoading = false;
        state.error = null;
      })
      .addCase(getDistricts.rejected, (state, action) => {
        state.districtsLoading = false;
        state.error = action.payload;
      })
      .addCase(getWards.pending, (state) => {
        state.wardsLoading = true;
        state.error = null;
      })
      .addCase(getWards.fulfilled, (state, action) => {
        state.wardsLoading = false;
        state.error = null;
      })
      .addCase(getWards.rejected, (state, action) => {
        state.wardsLoading = false;
        state.error = action.payload;
      })
      .addCase(gHNExpressFee.pending, (state) => {
        state.shippingFeeLoading = true;
        state.error = null;
      })
      .addCase(gHNExpressFee.fulfilled, (state, action) => {
        state.shippingFeeLoading = false;
        state.error = null;
      })
      .addCase(gHNExpressFee.rejected, (state, action) => {
        state.shippingFeeLoading = false;
        state.error = action.payload;
      });
  },
});

export default expressServiceSlice.reducer;
