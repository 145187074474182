import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './Banner.module.scss';

import routes from '~/config/routes';
import images from '~/assets/images';
import Button from '~/components/Button';
import { LoadingIcon, SearchIcon, CircleCloseIcon } from '~/components/Icons';
import { searchProduct } from '~/services/productService';

const cx = classNames.bind(styles);

function Banner({ className, onSearch, onSearchResult, onPage, onRemove, onHandleRemove }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const reduxData = useSelector((state) => state.product);

  const listBanner = [images.cafe3, images.cafe6, images.cafe4, images.cafe5, images.cafe7];

  const [bannerPath, setBannerPath] = useState(1);
  const [greeting, setGreeting] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const inputRef = useRef();

  // Hàm call api
  const fetchApi = async () => {
    setIsLoading(true);
    onSearch('loading');
    onSearchResult(searchResult);

    dispatch(searchProduct({ limit: 9, keyword: searchValue, page: 1 })).then((result) => {
      if (result.payload.code === 200) {
        onSearchResult(result.payload.data.products);
      }
    });
    onSearch('true');
    setIsLoading(false);
  };

  // Call api khi nhấn tìm kiếm
  const handleClick = () => {
    if (!searchValue.trim()) {
      return setSearchResult([]);
    }
    onHandleRemove();

    fetchApi();

    // Cuộn trang xuống phần kết quả tìm kiếm
    const windowHeight = window.innerHeight - 88;
    const isMobile = window.innerWidth <= 768;
    const adjustedWindowHeight = isMobile ? windowHeight / 2 : windowHeight;
    window.scrollTo({ top: adjustedWindowHeight, behavior: 'smooth' });
  };

  // Two way binding
  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (!searchValue.startsWith(' ')) {
      setSearchValue(searchValue);
    }
  };

  // Call api khi nhấn enter

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  //Clear input khi bấm nút close
  const handleClear = () => {
    if (isLoading) {
      return;
    }
    setSearchValue('');
    // inputRef.current.focus();
  };

  useEffect(() => {
    setIsMounted(true); // Thiết lập mounted là true khi component mount
    return () => {
      setIsMounted(false); // Reset mounted là false khi component unmount
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPage]);

  // Thiết lập câu chào của banner theo thời gian thực
  useEffect(() => {
    const date = new Date();
    const hours = date.getHours();

    if (hours >= 0 && hours <= 12) {
      setGreeting(t('home-banner.heading01'));
    } else if (hours > 12 && hours <= 18) {
      setGreeting(t('home-banner.heading02'));
    } else {
      setGreeting(t('home-banner.heading03'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Random ảnh của banner khi tải lại trang
  useEffect(() => {
    const randomPath = Math.floor(Math.random() * 3);
    setBannerPath(randomPath);
  }, []);

  useEffect(() => {
    handleClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRemove]);

  return (
    <div className={cx('banner')} style={{ backgroundImage: `url(${listBanner[bannerPath]})` }}>
      <div className={cx('container gx-5')}>
        <div className={cx('banner__content-wrapper')}>
          <div className={cx('banner__content')}>
            <h4 data-aos="zoom-in-up" className={cx('banner__greeting')}>
              {greeting}
            </h4>
            <h4 data-aos="zoom-in-up" className={cx('banner__caption')}>
              {t('home-banner.heading04')}
            </h4>
            <p data-aos="zoom-out" className={cx('banner__desc')}>
              {t('home-banner.desc01')}
            </p>

            <div className={cx('banner__search')}>
              <div className={cx('banner__search-container')}>
                <input
                  ref={inputRef}
                  id="banner-search"
                  name="banner-search"
                  type="text"
                  placeholder={t('home-banner.placeholder')}
                  value={searchValue}
                  autoComplete="off"
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  className={cx('banner__input-search')}
                />

                {searchValue && (
                  <div onClick={handleClear}>
                    <CircleCloseIcon className={cx('close-icon')} />
                  </div>
                )}
                {!isLoading && (
                  <div onClick={handleClick}>
                    <SearchIcon className={cx('search-icon')} />
                  </div>
                )}
                {isLoading && <LoadingIcon className={cx('search-icon', 'banner__search-spinning-icon')} />}
              </div>
            </div>

            <Link to={routes.restaurants}>
              <Button more primary>
                {t('button.btn20')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
