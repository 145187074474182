import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Modal } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import styles from './Chats.module.scss';

import ChatBotModal from '~/Layouts/components/Chats/ChatBotModal';
import Sidebar from '~/Layouts/components/Chats/ChatMessage/ChatModal/sidebar/Sidebar';
import MessageContainer from '~/Layouts/components/Chats/ChatMessage/ChatModal/messages/MessageContainer';
import { useChatContext } from '~/Layouts/components/Chats/ChatMessage/context/ChatContext';

const cx = classNames.bind(styles);

function Chats() {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const authUser = JSON.parse(localStorage.getItem('user'));

  const [openChatBot, setOpenChatBot] = useState(false);
  const { isOpen, closeModal, openModal } = useChatContext();

  const handleCloseChatBot = () => {
    setOpenChatBot(false);
  };

  const handleOpenModal = (type) => {
    if (!authUser) {
      toast.warning(t('errors.err12'));
      return navigator('/auth/login');
    }
    if (type === 'chat-bot') {
      setOpenChatBot(true);
    }
    if (type === 'chat') {
      openModal();
    }
  };

  useEffect(() => {
    const disableScroll = (event) => {
      event.preventDefault();
    };

    if (openChatBot || isOpen) {
      window.addEventListener('wheel', disableScroll, { passive: false });
      document.documentElement.style.overflow = 'hidden';
    } else {
      window.removeEventListener('wheel', disableScroll, { passive: false });
      document.documentElement.style.overflow = 'auto';
    }

    return () => {
      window.removeEventListener('wheel', disableScroll, { passive: false });
      document.documentElement.style.overflow = 'auto';
    };
  }, [openChatBot, isOpen]);

  return (
    <div data-aos="fade-up-left" className={cx('chats')}>
      {/* <Chat Bot /> */}
      <div className={cx('chat-bot')} onClick={() => handleOpenModal('chat-bot')}>
        <SupportAgentIcon className={cx('chat-bot__icon')} />
      </div>
      <Modal sx={{ zIndex: 99999, fontSize: 24 }} open={openChatBot} onClose={handleCloseChatBot}>
        <Box>
          <ChatBotModal closeModal={handleCloseChatBot} />
        </Box>
      </Modal>

      {/* Chat Message */}
      <div className={cx('chat')} onClick={() => handleOpenModal('chat')}>
        <ChatIcon className={cx('chat__icon')} />
      </div>
      <Modal open={isOpen} onClose={closeModal} style={{ zIndex: 9999 }}>
        <Box className={cx('chat__modal')}>
          <Sidebar />
          <MessageContainer closeModal={closeModal} />
        </Box>
      </Modal>
    </div>
  );
}

export default Chats;
